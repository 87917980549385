<template>
  <Page title="">
    <v-row>
      <v-col cols="12">
        <Panel title="EOS Insights FAQ">
          <div class="ma-3 pb-2">
            <p>
              <b>Q: What data does EOS Insights show, and where does it come from?</b>
            </p>
            <p>
              <ul>
                <li>
                  <b>Included products:</b> EOS Insights includes commercial (and some consumer) products covered by all policies—fixed, modern, and component. We currently have ~700 products in our database. Data in our tools is refreshed whenever it is updated in our backend system.
                </li>
                <li>
                  <b>Lifecycle event dates:</b> Lifecycle event dates come from our internal lifecycle database e.g., start date, mainstream retirement, etc. This database also provides lifecycle data for our public site on docs.ms.com/lifecycle. This ensures our data is consistent across our lifecycle tools.
                </li>
                <li>
                  <b>Customer data:</b> the Customers tab shows your customers, their products, and lifecycle dates for those products.
                  <ul>
                    <li>
                      We map you to your customers using MS Sales data which is why viewing the tab requires and active MS Sales account. We base access on the subsidiaries you have permissions to see in MS Sales. E.g., if you have MS Sales permissions to see the North American subsidiary you will see all customers in that sub when you go to the EOS Insights Customers tab. You can narrow down what you see when you come back to the Customers tab on subsequent visits by using the Favorites feature.
                    </li>
                    <li>
                      We determine the products we show for each of your customers on data in the PES support database which in turn maps to category IDs in MS Sales.
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
            <p>
              <b>Q: Where can I find migration information?</b>
            </p>
            <p>
              The <a href="https://aka.ms/migratenow" target="_blank">Migrate Now</a> deck provides migration options. This deck can be shared with external customers.
            </p>
            <p>
              <b>Q: Is the lifecycle data available for customers?</b>
            </p>
            <p>
              Yes, this tool uses the same data source as the external lifecycle site (<a href="https://learn.microsoft.com/lifecycle" target="_blank">learn.microsoft.com/lifecycle</a>).
            </p>
          </div>
        </Panel>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
export default {
}
</script>
